import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useSiteMetadata } from "../../service/useSiteMetadata"
import { useAxiosClient } from "../../service/useAxiosClient"
const DEFAULT_IMAGE_WIDTH = 350
const DEFAULT_IMAGE_HEIGHT = 350
function SEO({ title, description, image: dynamicImg, url = "" }) {
  const [articles, setArticles] = useState()

  const id = url.split("/")[4]
  const [{ data }] = useAxiosClient({
    url: `/publications/${id}/new/`,
    method: "GET",
  })
  const {
    description: defaultDescription,
    title: defaultTitle,
    twitterUsername,
    siteUrl,
    image,
    fbID,
  } = useSiteMetadata()
  if (title === undefined) {
    title = articles?.title
    description = articles?.summary
    dynamicImg = articles?.block1_image
  }
  useEffect(() => {
    if (data && data.length) {
      setArticles(data[0])
    }
  }, [data])
  return (
    <GatsbySeo
      title={title || defaultTitle}
      description={description || defaultDescription}
      openGraph={{
        url: siteUrl + url,
        title: title || defaultTitle,
        description: description || defaultDescription,
        images: [
          {
            url: dynamicImg || image,
            width: DEFAULT_IMAGE_WIDTH,
            height: DEFAULT_IMAGE_HEIGHT,
          },
        ],
        site_name: defaultTitle,
        type: "website",
      }}
      twitter={{
        handle: "@handle",
        site: twitterUsername,
        cardType: "summary_large_image",
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: dynamicImg || image,
      }}
      metaTags={[
        {
          property: "og:image:secure_url",
          content: `[${dynamicImg || image}]`,
        },
        {
          property: "og:image",
          itemProp: "image",
          content: `[${dynamicImg || image}]`,
          name: "image"
        },
        {
          property: "fb:app_id",
          content: fbID,
        },
      ]}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
